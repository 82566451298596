
export function generateServiceWorker(appName, version) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('./serviceworker.js?v=' + version)//questa è la versione appName.host
            //navigator.serviceWorker.register('./' + appName + '/serviceworker.js?v=' + version) // questa è la versione host/appName
            .then((reg) => {
                //  evento per la verifica di un aggiornamento dell'app
                reg.addEventListener('updatefound', () => {
                    if (

                        window.confirm(
                            "c'è una nuova versione, vuoi aggiornare ?"
                        )
                    ) {
                        window.location.reload();
                    }
                });
            }).catch(function (err) {
                console.log('Service Worker registration failed: ', err);
            });
    }
}
export function generateManifest(appName) {
    //  crea lo start url pwa
    let location = window.location;

    let startUrl = location.protocol + "//" + location.hostname + (location.port === "" ? "" : ":" + location.port) + "/?" + appName;//"/index.html";
    //  crea il path per le icone
    let iconsUrl = location.protocol + "//" + location.hostname + (location.port === "" ? "" : ":" + location.port + "/") + "/icons/";
    //  crea il manifesto JSON
    let manifest = {
        "name": appName,
        "short_name": appName,
        "start_url": startUrl,
        "id": appName,
        "gcm_sender_id": "722427180277",
        "display": "standalone",
        //"display": "fullscreen",
        "background_color": "#3E4EB8",
        //"theme_color": "#2F3BA2",
        "theme_color": "#FF0000",
        "orientation": "portrait",
        "prefer_related_applications": false,
        "description": "app di prova",
        "icons": [
            {
                "src": iconsUrl + "128x128.png",
                "sizes": "128x128",
                "type": "image/png",
                "orientation": "portrait",
            },
            {
                "src": iconsUrl + "144x144.png",
                "sizes": "144x144",
                "type": "image/png",
                "orientation": "portrait",
            },
            {
                "src": iconsUrl + "152x152.png",
                "sizes": "152x152",
                "type": "image/png",
                "orientation": "portrait",
            },
            {
                "src": iconsUrl + "192x192.png",
                "sizes": "192x192",
                "type": "image/png",
                "orientation": "portrait",
                "purpose": "maskable",
            },
            {
                "src": iconsUrl + "256x256.png",
                "sizes": "256x256",
                "type": "image/png",
                "orientation": "portrait",
            },
            {
                "src": iconsUrl + "512x512.png",
                "sizes": "512x512",
                "type": "image/png",
                "orientation": "portrait",
                "purpose": "maskable",

            },
        ],
    };
    //  esegue la stringify del manifest json
    let content = encodeURIComponent(JSON.stringify(manifest));
    //  crea il response url per il manifest
    let url = "data:application/manifest+json," + content;
    //  crea lelelment link per l'HEAD della index.html
    let element = document.createElement('link');
    element.setAttribute('rel', 'manifest');
    element.setAttribute('href', url);
    //  inserisce il link del manifesto in index.html
    document.querySelector('head').appendChild(element);
}