import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";



var firebaseConfig = {
    apiKey: "AIzaSyDkLW-3T7Tc7Ux1cdmZerNq02JHEE9DOUo",
    authDomain: "appizando.firebaseapp.com",
    projectId: "appizando",
    storageBucket: "appizando.appspot.com",
    messagingSenderId: "722427180277",
    appId: "1:722427180277:web:419ec4a842b8ecdde601bc",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

//  riceve il token push del divice corrente
export function getTokenA(appName) {
    return getToken(messaging, { vapidKey: 'BPVpw8-s7czmybBcNRr84g4Ry0Yyu-uRUf71w8mTIfVRJfWoW1ZP_O20i3HwgJFqxu9zN47EUjusuUKLWPfA110' }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client:', currentToken);
            // subscribe al topic sviluppo
            subscribeTokenToTopic(currentToken, "sviluppo");
            //  appName
            subscribeTokenToTopic(currentToken, appName);

            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

// riceve in backgorund le notifiche push e manda una notifica al device
onMessage(messaging, (payload) => {
    const notifTitle = payload.notification.title;
    const notifBody = payload.notification.body;
    const options = {
        body: notifBody,
        // icon: notifImg,
    };
    new Notification(notifTitle, options);
});

// iscrive il device ai topic necessari
function subscribeTokenToTopic(token, topic) {
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'key=AAAAqDQD9PU:APA91bG9aS2lPj01h4BnJKGKGWbAZ3bxnRWEfw43ZkxI82VXgxtjfy4VVTKlw_d7SYZE8ZbCCfCRM2T9see-U3VcMeyG-4mV5TSdH3WBcBlZJ8k4SNfYycPULtrRhMHX491xhCRz5Jdm'
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
        }
        console.log('Subscribed to "' + topic + '"');
    }).catch(error => {
        console.error(error);
    })
}
