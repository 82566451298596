import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { generateServiceWorker, generateManifest } from './pwa.jsx'
import { getTokenA } from './firebase.jsx';

let appName = "pwatest"
function App() {

    const [notifiche, setNotifiche] = useState([])

    useEffect(() => {
        console.log('APP START', appName)
        generateServiceWorker(appName, 4);
        generateManifest(appName);
        let refresh = window.setInterval(function () {
            refreshContent()
        }, 5000)
    }, [])

    const refreshContent = () => {
        console.log('refreshhhhh');
        let local = localStorage.getItem('pushStore');
        console.log(JSON.parse(local));
        if (local !== null) {
            setNotifiche(JSON.parse(local))
        }
        else {
            let temp = [];
            temp.push({ title: 'no push', body: (new Date()).getTime() });
            setNotifiche(temp);
            console.log(temp);
        }
    }

    const activatePush = () => {
        console.log('activate push', appName)
        navigator.serviceWorker.ready
            .then(reg => {
                //  richiede la permission per ricevere notifiche push
                Notification.requestPermission()
                    .then(result => {
                        if (result === 'granted') {
                            getTokenA(appName);
                            alert('permesso concesso');
                        }
                        else
                            alert('permesso negato');
                    });
                const options = {
                    userVisibleOnly: true,
                    applicationServerKey: "BPVpw8-s7czmybBcNRr84g4Ry0Yyu-uRUf71w8mTIfVRJfWoW1ZP_O20i3HwgJFqxu9zN47EUjusuUKLWPfA110"
                };
                reg.pushManager.subscribe(options).then(
                    (pushSubscription) => {
                        alert(pushSubscription.endpoint);
                        // The push subscription details needed by the application
                        // server are now available, and can be sent to it using,
                        // for example, an XMLHttpRequest.
                    },
                    (error) => {
                        // During development it often helps to log errors to the
                        // console. In a production environment it might make sense to
                        // also report information about errors back to the
                        // application server.
                        alert(error);
                    },
                );
            })
    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <input type='button' value='Premi qui' onClick={() => activatePush()} />
                <input type='button' value='Aggiorna' onClick={() => refreshContent()} />
                {
                    notifiche.map((item, idx) => {
                        return <p key={idx}>
                            {item.title.toUpperCase()}
                            <br />
                            {item.body}
                        </p>
                    })
                }
            </header>
        </div>
    );
}

export default App;
